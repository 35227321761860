





























































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  @Prop() invite: any
  @Prop() acceptLoading?: boolean
  @Prop() rejectLoading?: boolean
}
